var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout" }, [
    _c("div", { staticClass: "" }, [
      _c("h2", { staticClass: "font-weight-bold" }, [_vm._v("Setup")]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "card m-3 shadow-sm border-0" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", [_vm._v("Standardwerte")]),
            _vm._v(" "),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v("Hier klicken um Standardwerte einzufügen."),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _setup.setup },
              },
              [_vm._v("Jetzt Einrichten")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }